<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="ID" prop="id" v-if="dataForm.id">
          <el-input
            v-model="dataForm.id"
            :disabled="true"
            placeholder="ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="区域名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="区域名称"
          ></el-input>
        </el-form-item>
        <el-table-column label="供应商窗口">
          <el-button
            @click="addZoneOrg"
            size="mini"
            type="primary"
            :disabled="disabled"
            >新增
          </el-button>
        </el-table-column>
      </div>

      <el-table :data="dataForm.zoneOrgList" border style="width: 100%">
        <el-table-column
          prop="isCanteen"
          header-align="center"
          align="center"
          label="是否现场排队窗口"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isCanteen"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="orgId"
          header-align="center"
          align="center"
          label="供应商"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.orgId"
              v-if="scope.row.isCanteen"
              clearable
              filterable
              placeholder="请选择"
              :disabled="disabled"
            >
              <el-option
                v-for="(item, index) in orgList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="窗口号">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.windows"
              :min="1"
              :max="100"
              size="small"
              :disabled="disabled"
              placeholder="窗口号"
            />
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="打印机器码"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.printMachine"
              v-if="scope.row.isCanteen"
              :disabled="disabled"
              placeholder="打印机器码"
            />
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="窗口显示内容"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.text"
              v-if="!scope.row.isCanteen"
              :disabled="disabled"
              placeholder="窗口显示内容"
            />
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="deleteHandle(scope.$index)"
              :disabled="disabled"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { normal } from '@/mixins';
import { getOrgList } from '@/utils/options.js';
export default {
  mixins: [normal],
  components: {},
  data() {
    return {
      disabled: false,
      visible: false,
      orgList: [],
      dataForm: {
        id: 0,
        name: '',
        zoneOrgList: [],
      },
      dataRule: {},
    };
  },
  created() {
    this.getOrgList();
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.dataForm.zoneOrgList = [];
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (id) {
          this.$http({
            url: `/os/zone/info/${id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = data.zone;
            }
          });
        }
      });
    },
    //供应商列表
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    addZoneOrg() {
      this.dataForm.zoneOrgList.push({
        id: '',
        orgId: '',
        isCanteen: true,
        text: '',
        windows: '',
        printMachine: '',
      });
    },
    deleteHandle(index) {
      this.dataForm.zoneOrgList.splice(index, 1);
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/os/zone/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
